<template>
  <section>
    <b-container fluid class="px-0 h-100">
      <b-row no-gutters class="align-items-center h-100">
        <b-col md="6">
          <article class="text-center">
            <h2 class="my-5 font-bold">{{ $t("title.investment") }}</h2>
            <read-more
              more-str=""
              less-str=""
              :text="investmentInvitation && investmentInvitation"
              class="description"
              :max-chars="250"
            ></read-more>
            <!-- <p
              class="description mx-auto mb-5"
              v-html="investmentInvitation"
            ></p> -->
            <b-btn
              variant="default"
              @click="overlayToggle"
              class="router-link mt-5"
              ><span>{{ $t("button.register") }}</span>
            </b-btn>
          </article>
        </b-col>
        <b-col md="6">
          <div class="video-wrap">
            <section class="img-wrap ratio-50">
              <div class="img-content">
                <b-img :src="require('@/assets/imgs/video.png')" fluid></b-img>
              </div>
            </section>
            <b-btn variant="link" @click="overlayToggleVideo"
              ><b-img :src="require('@/assets/imgs/video.svg')" fluid></b-img
            ></b-btn>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </section>
</template>

<script>
export default {
  name: "HomeInvestment",
  props: {
    investmentInvitation: {
      type: String,
      default: ""
    }
  },
  methods: {
    overlayToggle() {
      this.$store.dispatch("SET_OVERLAY");
    },
    overlayToggleVideo() {
      this.$store.dispatch("SET_OVERLAY", true);
    }
  }
};
</script>

<style lang="scss" scoped></style>
